<ng-container
  *ngIf="{
    packages: packages$ | async,
    price: price$ | async
  } as data"
>
  <app-package-panel [packages]="data.packages">
    <form [formGroup]="packageForm">
      <div>
        <div class="header">
          <div class="package-name">
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Search package reference name</mat-label>
                <input matInput formControlName="packageName" />
                <mat-error
                  *ngIf="packageNameControl.hasError('nameAlreadyExists')"
                >
                  Reference name exists already
                </mat-error>
              </mat-form-field>
              <div class="date">
                Created on
                {{ date | date: 'MM/d/yyyy' }}
                at
                {{ date | date: 'h:mm a' }}
              </div>

              <button
                mat-stroked-button
                color="primary"
                class="cdx-but-md back"
                routerLink="/"
              >
                Back to Initiate
              </button>
            </div>
          </div>

          <div class="body order">
            <mat-card class="order-panel">
              <span
                class="price-overview"
                (click)="priceOverview.showPriceOverview(data.price!.currency!)"
              >
                Price Overview
              </span>
              <div class="item">
                <div class="title">1. Number of Candidates</div>
                <div class="content gray">
                  <span>Candidates</span>
                  <app-number-picker
                    [control]="$any(numberOfCandidatesControl)"
                    [min]="1"
                    [default]="1"
                  ></app-number-picker>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="item">
                <div class="title">2. Market(s)</div>
                <div class="content gray">
                  <span>
                    <mat-checkbox
                      formControlName="individualRegion"
                      color="primary"
                      (click)="clearGlobalPackage()"
                    >
                      One country
                    </mat-checkbox>
                  </span>
                  <app-number-picker
                    [control]="$any(numberIndividualRegionControl)"
                    [min]="1"
                    [disable]="!individualRegion"
                  ></app-number-picker>
                </div>
                <div class="content white">
                  <span>
                    <mat-checkbox
                      formControlName="packOf5"
                      color="primary"
                      (click)="clearGlobalPackage()"
                    >
                      Pack of 5
                    </mat-checkbox>
                  </span>
                  <app-number-picker
                    [control]="$any(numberPackOf5Control)"
                    [min]="1"
                    [disable]="!packOf5"
                  ></app-number-picker>
                </div>
                <div class="content gray">
                  <span>
                    <mat-checkbox
                      formControlName="packOf10"
                      color="primary"
                      (click)="clearGlobalPackage()"
                    >
                      Pack of 10
                    </mat-checkbox>
                  </span>
                  <app-number-picker
                    [control]="$any(numberPackOf10Control)"
                    [min]="1"
                    [disable]="!packOf10"
                  ></app-number-picker>
                </div>
                <div class="content white">
                  <span>
                    <mat-checkbox
                      formControlName="packEU"
                      color="primary"
                      (click)="clearGlobalPackage()"
                    >
                      EU package
                    </mat-checkbox>
                  </span>
                </div>
                <div class="content gray">
                  <span>
                    <mat-checkbox
                      formControlName="global"
                      color="primary"
                      (click)="clearMarkets()"
                    >
                      Global
                    </mat-checkbox>
                  </span>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="item">
                <div class="title">3. Feature Level</div>

                <mat-button-toggle-group
                  formControlName="featureLevel"
                  name="switcher"
                  aria-label="Switcher"
                  value="EXPERT"
                >
                  <mat-button-toggle value="STANDARD" checked role="button"
                    >Standard</mat-button-toggle
                  >
                  <mat-button-toggle value="EXPERT" role="button"
                    >Expert</mat-button-toggle
                  >
                </mat-button-toggle-group>
              </div>
            </mat-card>
            <mat-card class="price-panel">
              <div class="price">
                <div class="label">Retail price for this package:</div>
                <div class="content">
                  {{
                    priceFormattingService.formatPrice(
                      data.price ? data.price.retailPrice! : 0,
                      data.price?.currency!
                    )
                  }}
                </div>
              </div>
              <div class="discount">
                <span
                  *ngIf="!!data.price?.retailPrice && !!data.price?.discount"
                  >Your discount:
                  {{
                    (
                      (data.price?.discount! / data.price?.retailPrice!) *
                      100
                    ).toFixed(1)
                  }}%
                </span>
              </div>
              <div class="cost">
                <div class="label">Your cost for this package:</div>
                <div class="content">
                  {{
                    priceFormattingService.formatPrice(
                      data.price ? data.price.yourPrice! : 0,
                      data.price?.currency!
                    )
                  }}
                </div>
              </div>
              <div class="remaining"></div>
              <button
                mat-flat-button
                color="primary"
                aria-label="cdx primary button"
                class="cdx-but-lg"
                (click)="submit(data.price!)"
                [disabled]="disabledSubmit"
              >
                I agree
              </button>
            </mat-card>
          </div>
        </div>
      </div>
    </form></app-package-panel
  >
</ng-container>
