import {
  ColumnApi,
  ColumnMovedEvent,
  ColumnState,
  ColumnVisibleEvent,
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { UserPreference } from '@compumark/bla-backend-client';
import { UserPreferencesService } from '@compumark/user-preferences-backend-specifications';
import _ from 'lodash';

@Injectable()
export class TableColumnService {
  public static readonly columnsOpenPanel = {
    id: 'columns',
    labelDefault: 'Columns',
    labelKey: 'columns',
    iconKey: 'columns',
    toolPanel: 'agColumnsToolPanel',
    minWidth: 225,
    maxWidth: 225,
    width: 225,
    toolPanelParams: {
      suppressRowGroups: true,
      suppressValues: true,
      suppressPivots: true,
      suppressPivotMode: true,
    },
  };

  private userPreference?: UserPreference;
  private preferenceName?: string;
  isGracePeriodColumn = false;

  constructor(private userPreferenceService: UserPreferencesService) {}

  initColumns(
    columnApi: ColumnApi,
    userPreference: UserPreference,
    preferenceName: string,
  ): void {
    this.userPreference = userPreference;
    this.preferenceName = preferenceName;
    const userPreferenceColumns = _.get(userPreference, preferenceName, {});

    if (preferenceName === 'threatTableColumns') {
      const checkboxAction = {
        colId: 'checkboxAction',
        width: 'NumberInt(100)',
        hide: false,
      };
      userPreferenceColumns.push(checkboxAction);
    }

    const columnState = columnApi.getColumnState();
    const sortedColumnState = this.getSortedColumnStateArray(
      userPreferenceColumns,
      columnState,
      columnApi,
    );
    columnApi.applyColumnState({
      state: sortedColumnState,
      applyOrder: true,
    });
  }

  onColumnChange(event: ColumnMovedEvent | ColumnVisibleEvent): void {
    const gracePeriodColumnArray = this.userPreference?.threatTableColumns?.filter(
      (f) => f.colId === 'gracePeriod',
    );

    const [
      indexGracePeriodColumnInit,
      indexTtabColumnInit,
      indexReputationColumnInit,
    ] = this.getLegalColumnsIndexes();

    let gracePeriodColumn = {};

    if (gracePeriodColumnArray !== undefined) {
      gracePeriodColumn = gracePeriodColumnArray[0];
    }

    _.assign(this.userPreference, {
      [this.preferenceName!]: event.columnApi.getColumnState().map((cs) => {
        return { colId: cs.colId, hide: cs.hide, width: cs.width } as any;
      }),
    });

    const indexesAfterColumnChangedEvent = this.getLegalColumnsIndexes();
    const indexTtabColumnChanged = indexesAfterColumnChangedEvent[1];
    const indexReputationColumnChanged = indexesAfterColumnChangedEvent[2];

    this.handleColumnMovedEventLegalColumn(
      indexGracePeriodColumnInit,
      indexTtabColumnInit,
      indexTtabColumnChanged,
      indexReputationColumnInit,
      indexReputationColumnChanged,
      gracePeriodColumn,
    );
  }

  private getLegalColumnsIndexes(): number[] {
    const indexGracePeriod = this.userPreference?.threatTableColumns!.findIndex(
      (col) => col.colId === 'gracePeriod',
    );

    const indexTtab = this.userPreference?.threatTableColumns!.findIndex(
      (col) => col.colId === 'includesTtab',
    );

    const indexReputation = this.userPreference?.threatTableColumns!.findIndex(
      (col) => col.colId === 'reputation',
    );
    return [indexGracePeriod!, indexTtab!, indexReputation!];
  }

  public handleColumnMovedEventLegalColumn(
    indexGracePeriodColumnInit: number,
    indexTtabColumnInit: number,
    indexTtabColumnChanged: number,
    indexReputationColumnInit: number,
    indexReputationColumnChanged: number,
    gracePeriodColumn: any,
  ): void {
    let newGracePeriodColumnIndex = 0;

    if (indexGracePeriodColumnInit! < indexTtabColumnInit!) {
      newGracePeriodColumnIndex = indexTtabColumnChanged;
    } else if (indexGracePeriodColumnInit! > indexReputationColumnInit!) {
      newGracePeriodColumnIndex = indexReputationColumnChanged + 1;
    }

    if (
      !this.userPreference?.threatTableColumns?.some(
        (col) => col.colId === 'gracePeriod',
      )
    ) {
      this.userPreference?.threatTableColumns!.splice(
        newGracePeriodColumnIndex as number,
        0,
        gracePeriodColumn,
      );
    }

    this.userPreferenceService
      .saveUserPreferences(this.userPreference as any)
      ?.subscribe();
  }

  private getSortedColumnStateArray(
    userPreferenceColumns: any[],
    columnState: ColumnState[],
    columnApi: ColumnApi,
  ): ColumnState[] {
    const sortedArray: ColumnState[] = [];
    for (const column of columnState) {
      const userPrefCol = userPreferenceColumns.find(
        (c) => column.colId === c.colId,
      );

      if (!userPrefCol) {
        columnApi?.setColumnVisible(column.colId, false);
      }
    }

    const gracePeriodColumn = userPreferenceColumns.filter(
      (f) => f.colId === 'gracePeriod',
    );
    const index = userPreferenceColumns.findIndex(
      (col) => col.colId === 'gracePeriod',
    );

    for (const up of userPreferenceColumns) {
      if (columnState.find((c) => c.colId === up.colId) === undefined) {
        this.isGracePeriodColumn = true;
        continue;
      }
      const col = columnState.find((c) => c.colId === up.colId);
      col!.hide = up.hide;
      col!.width = up.width;
      if (!!col) {
        sortedArray.push(col!);
      }
    }

    if (this.isGracePeriodColumn) {
      sortedArray.splice(index, 0, gracePeriodColumn[0] as any);
      return sortedArray;
    } else {
      return sortedArray;
    }
  }
}
