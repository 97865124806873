<xng-breadcrumb [separator]="icon" *ngIf="enabled$ | async">
  <ng-container
    *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first"
  >
    <mat-icon *ngIf="info && info.icon">
      {{ info.icon }}
    </mat-icon>
    <ng-container *ngIf="!first">
      <span class="breadcrumb-label">
        {{ breadcrumb }}
      </span>
      <ng-container *ngIf="(items$ | async) && (items$ | async)!.length > 0">
        <section *ngIf="info && info.items">
          <button
            mat-button
            [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger"
            class="cdx-but-menu cdx-but-menu-icon"
            role="button"
          >
            <div
              class="arrow"
              [ngClass]="menuTrigger.menuOpen ? 'arrow' : 'close'"
            ></div>
          </button>
          <mat-menu #menu="matMenu" role="menu">
            <ng-container *ngFor="let item of info.items">
              <ng-container *ngIf="!item.resolver">
                <button
                  mat-menu-item
                  [routerLink]="resolvePath(item.path!)"
                  *ngIf="showItem((data$ | async)!, item)"
                >
                  {{ item.label }}
                </button>
              </ng-container>
              <ng-container *ngIf="!!item.resolver && item.resolver === 'wcl'">
                <app-wcl-menu-item
                  *ngIf="showItem((data$ | async)!!, item)"
                  [path]="resolvePath(item.path!)"
                  [data]="(data$ | async)!"
                  [item]="item"
                ></app-wcl-menu-item>
              </ng-container>
            </ng-container>
          </mat-menu>
        </section>
      </ng-container>
    </ng-container>
  </ng-container>
</xng-breadcrumb>

<ng-template #icon>
  <mat-icon>chevron_right</mat-icon>
</ng-template>
