<ng-container class="filter-template dialog">
  <form [formGroup]="filterPresetForm" novalidate>
    <div class="title" mat-dialog-title>
      <div>Save active filters as template</div>
      <button mat-icon-button>
        <mat-icon mat-dialog-close>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content class="filter-template">
      <div class="col-5 mb-3">
        <mat-form-field appearance="outline">
          <mat-label>Template Name</mat-label>
          <input
            type="text"
            matInput
            placeholder="Template name"
            formControlName="name"
            maxlength="50"
            #filterTextInput
            [(ngModel)]="templateName"
            [ngClass]="{
              'is-invalid': submitted && getFilterPresetForm.name.errors
            }"
          />
        </mat-form-field>
        <div class="save-filter-counts">
          <div class="error-contain">
            <div
              *ngIf="submitted && getFilterPresetForm.name.errors"
              class="alert"
            >
              <div *ngIf="!getFilterPresetForm.name.errors.valid">
                That name is already taken. Please choose another name.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="active-filter-chips">
        <label class="form-label">Active Filters</label>
        <mat-chip-list>
          <div>
            <mat-chip
              *ngFor="let filterChip of filterChips"
              (removed)="removeChip(filterChip)"
              selectable="false"
            >
              <span class="header-name">{{ filterChip.label }}:</span>
              {{ getValueLabel(filterChip) }}
              <ng-container *ngIf="!!filterChip.additionalFilter">
                <pre> - </pre>
                <span
                  *ngIf="!!filterChip.additionalFilter.label"
                  class="header-name"
                  >{{ filterChip.additionalFilter.label }}:</span
                >
                {{ getValueLabel(filterChip.additionalFilter) }}
              </ng-container>

              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </div>
        </mat-chip-list>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button
        class="cdx-but-lg"
        color="primary"
        mat-stroked-button
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="submitBtn"
        [disabled]="!saveFilterBtnEnabled()"
        (click)="onSubmit()"
      >
        Save template
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
