import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterChip } from '@compumark/brand-context-components';
import _ from 'lodash';
import { UserService } from 'src/app/security/services/user.service';
import { FilterChipsCallbackHandlerService } from '../../services/filter-chips-callback-handler.service';

@Component({
  selector: 'app-edit-filter-dialog',
  templateUrl: './edit-filter-dialog.component.html',
  styleUrls: ['./edit-filter-dialog.component.scss'],
})
export class EditFilterDialogComponent implements OnInit {
  public filterPresetForm!: FormGroup;
  public submitted = false;
  templateName = '';
  @ViewChild('filterTextInput') textInput: ElementRef | undefined;
  public userPreference: any = undefined;
  public savedFilterName: string[] = [];
  public filterTemplateForEditing: any;
  public filterChips: FilterChip[] = [];
  public filterModel: any;
  public gridApi: any;

  constructor(
    private dialogRef: MatDialogRef<EditFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private filterChipsCallbackHandlerService: FilterChipsCallbackHandlerService,
  ) {
    this.userService.getCurrentUserObservable()?.subscribe((u) => {
      this.userPreference = u.preference!;
      this.savedFilterName = this.userPreference!.filterTemplates?.map(
        (ft: { templateName: any }) => ft.templateName,
      );
    });
    this.filterTemplateForEditing = data.filterTemplateForEditing;
    this.filterChips = this.data.filterChips;
    this.filterModel = this.data.gridApi?.getFilterModel();
    this.gridApi = this.data.gridApi;
  }

  ngOnInit(): void {
    this.templateName = this.filterTemplateForEditing?.templateName;
    this.filterPresetForm = this.formBuilder.group({
      name: ['', [Validators.required, this.duplicateNameValidator.bind(this)]],
    });
  }

  public getValueLabel(chip: FilterChip): string {
    return chip.value;
  }

  public isFilterChipsEmpty(): boolean {
    return this.filterChips?.length === 0;
  }

  public saveFilterBtnEnabled(): boolean {
    return !this.isFilterChipsEmpty() && this.filterPresetForm.valid;
  }

  duplicateNameValidator(control: AbstractControl): any {
    if (this.isDuplicatePresetName(control)) {
      this.submitted = true;
      return { valid: false };
    } else if (control.value?.trim().length === 0) {
      return { valid: false };
    }
    this.submitted = false;
    return null;
  }

  isDuplicatePresetName(control: AbstractControl): boolean {
    return (
      this.savedFilterName?.filter(
        (savedFilterName) =>
          savedFilterName?.toLowerCase().replace(/\s/g, '') ===
          control.value.toLowerCase().replace(/\s/g, ''),
      ).length > 0
    );
  }

  public removeChip(filterChip: FilterChip): void {
    const index = this.filterChips?.findIndex((fc) =>
      _.isEqualWith(
        fc,
        filterChip,
        this.filterChipsCallbackHandlerService.filterChipComparator,
      ),
    );
    this.filterChips.splice(index, 1);

    this.filterChipsCallbackHandlerService.refreshTableAfterRemoveFilterChip(
      filterChip,
      this.gridApi,
    );
  }

  get getFilterPresetForm(): any {
    return this.filterPresetForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.filterPresetForm.invalid) {
      return;
    }
    this.dialogRef.close({
      data: {
        templateName: this.filterPresetForm.controls.name.value.trim(),
      },
    });
  }
}
