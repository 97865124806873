import { AgGridModule } from '@ag-grid-community/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ErrorStateMatcher,
  MatNativeDateModule,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule, Configuration } from '@compumark/bla-backend-client';
import {
  BrandContextComponentsModule,
  GlasspaneModule,
  GlasspaneService,
} from '@compumark/brand-context-components';
import {
  BrandContextApiModule,
  BrandContextConfiguration,
} from '@compumark/brand-context-components-backend-client';
import {
  UserPreferencesApiModule,
  UserPreferencesConfiguration,
} from '@compumark/user-preferences-backend-specifications';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomTextFilterComponent } from './custom-text-filter/customTextFilter.component';
import { AppFooterModule } from './footer/footer.module';
import { FullTextModule } from './full-text/full-text.module';
import { HeaderModule } from './header/header.module';
import { ImageTooltipComponent } from './image-tooltip/components/image-tooltip/image-tooltip.component';
import { BrandLandscapeRefreshService } from './isolate/services/brand-landscape-refresh.service';
import { NotifyOnErrorInterceptor } from './notification/interceptors/notify-on-error.interceptor';
import { NotificationModule } from './notification/notification.module';
import { AuthenticationInterceptor } from './security/interceptors/authentication.interceptor';
import { AuthorizationInterceptor } from './security/interceptors/authorization.interceptor';
import { TermsAndConditionsInterceptor } from './security/interceptors/terms-and-conditions.interceptor';
import { SecurityModule } from './security/security.module';
import { AnalyticsService } from './security/services/analytics.service';
import { GoodsAndServicesDialogOpeningService } from './threats-tables/products-dialog/services/goods-and-services-dialog-opening-service';
import { RetryInterceptor } from './util/interceptors/retry.interceptor';

import { UtilModule } from './util/util.module';
import { NgxColorsModule } from 'ngx-colors';
import { SaveFreetextRendererComponent } from './save-freetext-renderer/save-freetext-renderer.component';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

export function apiModuleConfigurationFactory(): Configuration {
  return new Configuration({
    basePath: environment.backendBaseUrl,
    withCredentials: true,
  });
}

export function windowFactory(): Window {
  return window;
}

export function pendoIdFormatter(value: any): string {
  return value.toString().toLowerCase();
}

export function brandContextModuleConfigurationFactory(): BrandContextConfiguration {
  return new BrandContextConfiguration({
    basePath: environment.backendBaseUrl + '/brand-context',
    withCredentials: true,
  });
}

export function userPreferenceModuleConfigurationFactory(): UserPreferencesConfiguration {
  return new UserPreferencesConfiguration({
    basePath: environment.backendBaseUrl,
    withCredentials: true,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    CustomTextFilterComponent,
    ImageTooltipComponent,
    SaveFreetextRendererComponent,
  ],
  imports: [
    ApiModule.forRoot(apiModuleConfigurationFactory),
    BrandContextApiModule.forRoot(brandContextModuleConfigurationFactory),
    UserPreferencesApiModule.forRoot(userPreferenceModuleConfigurationFactory),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FullTextModule,
    AppFooterModule,
    HeaderModule,
    HttpClientModule,
    MatProgressBarModule,
    MatDialogModule,
    NotificationModule,
    SecurityModule,
    GlasspaneModule,
    AgGridModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    BrandContextComponentsModule,
    UtilModule,
    NgxColorsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    AnalyticsService,
    GlasspaneService,
    GoodsAndServicesDialogOpeningService,
    BrandLandscapeRefreshService,
    { provide: 'nativeWindow', useFactory: windowFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotifyOnErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TermsAndConditionsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class AppModule {}
