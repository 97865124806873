<div>
  <mat-form-field
    class="cdx-input-default"
    appearance="outline"
    class="custom-input-field"
  >
    <input
      matInput
      #input
      [(ngModel)]="freeTextData"
      placeholder="Enter Text"
      (blur)="saveFreeText($event)"
    />
  </mat-form-field>
</div>
